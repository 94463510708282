<template>
    <div class="about-container">
     <img src="@/assets/img/kept_logo_1.svg" alt="Kept">

        <h1> Actool </h1>
        <h2> ПО для актуарных расчетов позволяет производить расчеты наилучших оценок (резервов убытков,
        резервов расходов на урегулирование, ожидаемых прав на регрессные требования и ГОТС и т.д) различными
        актуарными методами, поправок на нефинансовый риск (отдельно для ООЧП и ОВТ) и денежных потоков в соответствии
        с МСФО 17. Расчеты выполняются на основании агрегированных данных, загружаемых в формате xlsx, результаты
        выгружаются в формате xlsx. </h2>


        <p> Использование Actool регулируется лицензионным соглашением с Kept и не
        предлагается аудиторским клиентам Kept или их аффилированным и связанным лицам. Согласно применимым правилам
        аудиторской независимости, Kept не сможет предоставлять сопровождение, обновление или техническую поддержку
        Actool для таких клиентов. </p>

        <p> Любые результаты обработки информации Actool являются нашим толкованием
        соответствующих норм законодательства и стандартов бухгалтерского учета, действующих на момент передачи
        Actool или его обновлений. </p>

        <p> Полная и исключительная ответственность за полноту и достоверность исходных данных и заполнение
        конфигурационного файла Actool сохраняется за Вами.
        В случае внесения Вами изменений в форматы шаблонов входных денежных потоков, в конфигурационный файл или
        иных изменений в Actool Kept не предоставляет каких-либо гарантий и
        не несет ответственность за работоспособность Actool и корректность реализации
        его функционала. </p>

        <p> Результат обработки информации Actool ограничен по области анализа его
        функционалом, поэтому на Вас лежит ответственность по оценке применимости результата обработки информации
        Actool в той или иной ситуации с учетом ее специфики. </p>

        <p> Kept не предоставляет Вам лицензии на право использования программного обеспечения сторонних
        производителей (например, Python, PostgreSQL), которое может потребоваться согласно предоставленной
        Вам документации на Actool. Вы несете полную ответственность за получение
        прав на использование указанного программного обеспечения и за использование Actool в соответствии с договором с Kept и с предоставленной Вам документацией на него. </p>

     </div>

    <div id="disclaimer">
        <p> © 2025 г. ООО «Кэпт Налоги и Консультирование». Все права защищены. </p>
    </div>
</template>


<style scoped>
.about-container{
    margin: 200px;
}

img {
    height: 8rem;
    margin-bottom:50px;
}

p {
    font-size:30px;
    margin-bottom: 50px;
}

h1{
    font-size: 80px;
    text-align:center;
    margin-bottom: 40px;
}

h2{
    font-size: 30px;
    text-align:center;
    margin-bottom: 100px;
}

#disclaimer{
    margin-left: 30px;
    position: fixed;
    bottom: 0;
}

</style>
